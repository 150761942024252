import "./App.css";
import { Route, Routes } from "react-router";
import { Home } from "./components/Home";
import { Courses } from "./components/Courses";
import { Header } from "./components/Header";
import { Contact } from "./components/Contact";
import { Agb } from "./components/Agb";
import { DataProtection } from "./components/DataProtection";

function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kurse" element={<Courses />} />
        <Route path="/agb" element={<Agb />} />
        <Route path="/datenschutz" element={<DataProtection />} />
      </Routes>
      <Contact />
    </div>
  );
}

export default App;
