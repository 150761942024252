import { Helmet } from "react-helmet";
import "./Home.css";

export const Home = () => {
  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Herzens Baby - PEKiP® Kurse in Weyhe - Startseite</title>
        <link rel="canonical" href="https://herzens-baby.de" />
        <meta
          name="description"
          content="Mein Name ist Denise, ich bin gelernte Erzieherin und PEKiP® (Prager-Eltern-Kind-Programm) Gruppenleitung. In meinen PEKiP® Kursen möchte ich euch unterstützen euer Baby kennenzulernen und eine feste Bindung aufzubauen."
        />
      </Helmet>
      <div className="img-container">
        <h1 id="img-container">
          <a href={`https://herzens-baby.de/`}>
            <picture>
              <source
                srcSet="img/foot_and_hand/foot_and_hand_512.webp"
                type="image/webp"
                media="(max-width:600px)"
              />
              <source
                srcSet="img/foot_and_hand/foot_and_hand_1024.webp"
                type="image/webp"
                media="(max-width:900px)"
              />
              <source
                srcSet="img/foot_and_hand/foot_and_hand_2048.webp"
                type="image/webp"
                media="(min-width:900px)"
              />
              <source
                srcSet="img/foot_and_hand/foot_and_hand.webp"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <img
                src="/img/foot_and_hand/foot_and_hand.jpg"
                id="foot-and-hand-img"
                srcSet="image/webp"
                alt="Pekip Kurse Bremen Weyhe"
                loading="lazy"
              />
            </picture>
          </a>
        </h1>
        <div id="welcome-text">
          <h2 className="home-headline">Herzlich Willkommen liebe Eltern,</h2>
          <p className="home-subtext">
            Ich freue mich sehr, dass das Herzens-Baby endlich in euer Leben
            getreten ist.
            <br /> Gerne möchte ich euch durch das erste Lebensjahr begleiten
            <br /> und mit PEKiP® unterstützen euer Baby kennenzulernen
            <br /> und eine feste Bindung aufzubauen.
          </p>
        </div>
      </div>

      <div className="about-me">
        <div className="image-container">
          <picture>
            <source
              srcSet="img/denise_henry/denise_henry_512.webp"
              type="image/webp"
              media="(max-width:600px)"
            />
            <source
              srcSet="img/denise_henry/denise_henry_1024.webp"
              type="image/webp"
              media="(max-width:900px)"
            />
            <source
              srcSet="img/denise_henry/denise_henry_2048.webp"
              type="image/webp"
              media="(min-width:900px)"
            />
            <source
              srcSet="img/denise_henry/denise_henry.webp"
              type="image/webp"
              media="(min-width:1200px)"
            />
            <img
              src="/img/denise_henry/denise_henry.jpg"
              id="denise-herny-img"
              srcSet="image/webp"
              alt="..."
              loading="lazy"
            />
          </picture>
        </div>
        <p className="about-text centered-text">
          Mein Name ist Denise, ich bin Mama von zwei Kindern und wohne mit
          meiner Familie in Weyhe. Ich bin gelernte Erzieherin und PEKiP®
          Gruppenleitung. Seit 2015 arbeite ich mit großer Leidenschaft im
          Krippenbereich und habe mir mit der Weiterbildung zur PEKiP®
          Gruppenleitung einen Herzenswunsch erfüllt. Für mich ist PEKiP®
          (Prager-Eltern-Kind-Programm) die schönste Art der Gruppenarbeit mit
          Eltern und Kind.
        </p>
      </div>

      <div className="about-pekip">
        <div className="pekip">
          <img className="logo" src="/img/icons/pekip.svg" alt="pekip" />
          <p className="pekip-text centered-text">
            Das <a href="https://pekip.de/">Prager-Eltern-Kind-Programm</a> ist
            ein Konzept für die Gruppenarbeit mit Eltern und ihren Kindern im
            ersten Lebensjahr. Ziel des PEKiP® ist es, Eltern und Babys im
            sensiblen Prozess des Zueinanderfindens zu unterstützen, um
          </p>
          <ul>
            <li>
              Das Baby in seiner momentanen Situation und seiner Entwicklung
              wahrzunehmen, zu begleiten und zu fördern;
            </li>
            <li>
              Die Beziehung zwischen dem Baby und seinen Eltern zu stärken und
              zu vertiefen
            </li>
            <li>
              Die Eltern in ihrer Situation zu begleiten und den
              Erfahrungsaustausch sowie die Kontakte der Eltern untereinander zu
              fördern
            </li>
            <li>Dem Baby Kontakte zu Gleichaltrigen zu ermöglichen</li>
          </ul>
        </div>
        <div className="denise-pekip">
          <h2 className="centered-text goals">
            Meine Ziele als PEKiP® Gruppenleitung
          </h2>
          <p className="centered-text">
            Ein Baby verändert alles und wirft viele Fragen auf. Ich möchte euch
            in der Phase des Zueinanderfindens begleiten und individuell auf
            euch eingehen. Hierbei schauen wir gemeinsam auf eure momentane
            Situation und die Entwicklung des Babys. In einer Kleingruppe werden
            verschiedene Spiel-, Bewegungs- und Sinnesanregungen von mir als
            Gruppenleitung angeboten, sodass jedes Kind die Möglichkeit hat, je
            nach Entwicklungsstand entsprechend gefördert zu werden. In den 90
            Minuten werdet ihr intensiv Zeit mit eurem Kind verbringen, eine
            sehr wertvolle Zeit, die euren Bindungsaufbau sehr gut tun wird. Wir
            wollen in der Gruppe zusammen Themen besprechen, die euch
            beschäftigen und Erfahrungen austauschen. Mein Ziel ist es, dass aus
            der Gruppe eine Gemeinschaft wird, dass sowohl die Eltern als auch
            die Kinder zueinander finden und wir das erste Jahr gemeinsam
            verbringen.
          </p>
          <p className="centered-text">
            Die Babys können ab einem Alter von der 4.-6. Lebenswoche
            teilnehmen, allerdings ist jederzeit ein späterer Einstieg möglich.
            Während der Gruppenstunde sind die Babys nackt, welches ihnen die
            größtmögliche Bewegungsfreiheit bietet. Die PEKiP® Gruppe bleibt das
            ganze erste Lebensjahr zusammen. Ein Jahr besteht aus 4 Blöcken mit
            je 8 Treffen.
          </p>
          <p className="centered-text">
            Meine Kurse finden donnerstags in der Hebammenpraxis in Weyhe statt.
          </p>
        </div>

        <div className="course-setup">
          <h2 className="centered-text goals">Kursaufbau</h2>

          <p className="course-text centered-text">
            Ein Jahr in einer PEKiP® Gruppe besteht aus 4 Kursen:
          </p>

          <ul>
            <li>Ein Kurs besteht aus 8 Einheiten</li>
            <li>Jede Einheit dauert 90 Minuten</li>
            <li>Die Einheiten finden mit Ausnahmen wöchentlich statt</li>
            <li>Die Kosten für einen Kurs belaufen sich auf 160€</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
