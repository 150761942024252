import { Helmet } from 'react-helmet';
import './Agb.css';

export const Agb = () => {
  return (
    <div className='agbs'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Herzens Baby - PEKiP® Kurse in Weyhe - AGB's</title>
        <link rel="canonical" href="http://herzens-baby.de/kurse" />
        <meta
          name="description"
          content="Die Allgemeinen Geschäftsbedingungen für meine PEKiP® Kurse."
        />
      </Helmet>
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <h2>§ 1 Geltungsbereich</h2>
      <p>
        Für den Vertrag zwischen der Kursleiterin und dem/der
        Kursteilnehmer/innen gelten die nachfolgenden allgemeinen
        Geschäftsbedingungen, soweit nichts anderes bestimmt ist.
      </p>

      <h2>§ 2 Vertragsabschluss</h2>
      <p>
        Kurse bei Herzens-Baby müssen schriftlich, per E-Mail oder online über
        unsere Website Herzens-baby.de gebucht werden. Im Falle der Buchung per
        E-Mail oder über die Webseite erhalten Sie immer eine Bestätigung per
        E-Mail, dass die Buchung eingegangen ist (Buchungsbestätigung). Im Falle
        der schriftlichen Anmeldung erfolgt die Buchungsbestätigung ebenfalls
        schriftlich. Der Vertrag zwischen der Kursleiterin und dem
        Vertragspartner kommt mit Zugang der Buchungsbestätigung zustande. .
      </p>

      <ol>
        <li>
          Die Anmeldung zu einem Kurs kann bis zu vierzehn Tagen vor Kursbeginn
          ohne Angabe von Gründen storniert werden. Hierfür wird immer eine
          Stornierungsgebühr von 20€ fällig. Die Stornierung bedarf in jedem
          Fall der Schriftform. Erfolgt die Stornierung zu einem späteren
          Zeitpunkt, ist die volle Kursgebühr fällig. Dem/ der Kursteilnehmerin
          bleibt jeweils nachgelassen, zu beweisen, dass aufgrund der von ihr /
          ihm erfolgten Stornierung gar kein Schaden oder ein wesentlich
          niedriger Schaden entstanden ist. Wird von der Kursteilnehmer/ in
          ein/e Ersatzteilnehmer/in gefunden und vorgeschlagen, so muss sich die
          Kursteilnehmerin mit der Ersatzteilnehmer/ in finanziell einigen. Das
          Stellen einer Ersatzteilnehmer/ in ist nur mit Zustimmung der
          Kursleitung möglich .{" "}
        </li>
        <li>
          Sollten wir ausnahmsweise einmal einen Kurs mangels Teilnehmern
          absagen müssen, oder kann ein Kurs aus Gründen, die bei der
          Kursleitung liegen, nicht weitergeführt werden ( z.B. aufgrund einer
          Erkrankung der Kursleiterin) und können wir Ihnen keinen passenden
          Ersatzkurs anbieten, erstatten wir Ihnen die bereits gezahlten
          Gebühren vollständig bzw.anteilig zurück.
        </li>
        <li>
          Der Ausfall von einzelnen Terminen begründet keinen
          Erstattungsanspruch. Die Kursleiterin ist dennoch bemüht, in solchen
          Fällen Ersatztermine zu realisieren.
        </li>
        <li>Der Ausfall von Kursterminen laufender Kurse erfolgt per Email.</li>
        <li>
          Die Absage von Kursterminen durch die Teilnehmer/in erfolgt
          ausschließlich über E-Mail an herzens-baby@web.de
        </li>
      </ol>

      <h2>§ 3 Zahlung</h2>
      <ol>
        <li>
          Die Kursgebühr ist innerhalb von 14 Tagen nach Anmeldung entsprechend
          der Anmeldebestätigung per Banküberweisung zu entrichten. Sollte die
          Zahlungsgebühr auch nach Erhalt der Zahlungserinnerungen nicht
          eingegangen sein, behalten wir uns vor, die Anmeldung zu stornieren.
          Die / der Kursteilnehmer/in erhält bei regelmäßiger Teilnahme nach
          Abschluss des Kurses von der Kursleiterin eine Teilnahmebescheinigung
          zur Vorlage bei der Krankenkasse per E-Mail zugesandt.
        </li>
        <li>
          Bankverbindung:
          <table>
            <tr>
              <td>Kontoinhaber:</td>
              <td className='table-value'>Eike von Ohlen</td>
            </tr>
            <tr>
              <td>IBAN:</td>
              <td className='table-value'>DE20 4726 4367 4050 6348 06</td>
            </tr>
            <tr>
              <td>BIC:</td>
              <td className='table-value'>GENODEM1STM</td>
            </tr>
            <tr>
              <td>Kredit Institut:</td>
              <td className='table-value'>Vereinigte Volksbank eG</td>
            </tr>
          </table>
        </li>
      </ol>

      <h2>§ 4 Haftung</h2>
      <p>
        Die Teilnahme an allen von den Kursleiterinnen angebotenen Kursen
        erfolgt auf eigene Verantwortung. Für Garderobe und sonstiges Eigentum
        des Vertragspartners wird keinerlei Haftung übernommen. Herzens Baby
        haftet nach den gesetzlichen Bestimmungen.
      </p>

      <h2>§ 6 Schriftform/Sonstiges</h2>
      <p>
        Mündliche Nebenabreden bestehen nicht und bedürfen zu ihrer Wirksamkeit
        der Schriftform. Ergänzungen, Änderungen oder Nebenabreden zu diesen
        Bedingungen sowie einseitige Willenserklärungen bedürfen zu ihrer
        Wirksamkeit der schriftlichen Bestätigung der Kursleiterin. Dies gilt
        auch für die Aufhebung des Schriftformerfordernisses.
      </p>
    </div>
  );
};
