import "./Contact.css";

export const Contact = () => {
  return (
    <div id="#contact" className="contact">
      <div className="contact-links">
        <h3 className="contact-headline">Impressum</h3>

        <div className="contact-part">
          <div className="contact-part-header">
            <img
              className="icon"
              src="/img/icons/location-dot.svg"
              alt="location-dot"
            />
            <h4 className="text">Veranstaltungsort</h4>
          </div>

          <p>
            <a
              className="contact-part-text"
              href="http://www.hebammenpraxis-weyhe.de/"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Hebammenpraxis Weyhe</strong>
            </a>
            <a
              className="contact-part-text"
              href="https://www.google.com/maps/place/Hebammenpraxis+Weyhe/@52.9825324,8.8465135,19z/data=!3m2!4b1!5s0x47b0d9846f90ab23:0x9e6283c2075bd895!4m6!3m5!1s0x47b127745a00d03f:0x9298b437bb6fe940!8m2!3d52.9825316!4d8.8471572!16s%2Fg%2F11fz9zyy09?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              <br />
              Bahnhofsstr. 4
              <br />
              28844 Weyhe
              <br />
            </a>
          </p>
        </div>

        {/* <div className="contact-part">
            <div className="contact-part-header">
              <img className="icon" src="/img/icons/phone.svg" alt="phone" />
              <h4 className="text">Tel.</h4>
            </div>

            <a className="contact-part-text" href={"tel:..."}>
              ...
            </a>
          </div> */}

        <div className="contact-part">
          <div className="contact-part-header">
            <img className="icon" src="/img/icons/email.svg" alt="email" />
            <h4 className="text">E-Mail</h4>
          </div>

          <a
            className="contact-part-text"
            href="mailto:herzens-baby@web.de"
            color="inherit"
          >
            <strong>herzens-baby@web.de</strong>
          </a>
        </div>

        <div className="contact-part">
          <div className="contact-part-header">
            <img className="icon" src="/img/icons/user.svg" alt="user" />
            <h4 className="text">Inhaltlich Verantwortlich</h4>
          </div>

          <p className="contact-part-text">
            <strong>Denise von Ohlen </strong>
            <br />
            Gelsenkirchenerstr. 2
            <br />
            28844 Weyhe
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};
