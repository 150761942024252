import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import "./Header.css";

export const Header = () => {
  const navigate = useNavigate();

  return (
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Brand>Herzens Baby</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav onSelect={(k) => k && navigate(k)}>
            <Nav.Link eventKey="/">Startseite</Nav.Link>
            <Nav.Link eventKey="/kurse">Kurse</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  );
};
